import SeniorTechnicalArtistPipelineAndToolsDevelop from './pdf/Senior_Technical_Artist_Pipeline_and_Tools_Develop.pdf'
import GameDesignerPeer from './pdf/Game_Designer_Peer.pdf'

export const productAndDesign = [
  {
    id: 103,
    title: 'Game Designer Peer',
    location: 'Remote',
    time: 'Full Time',
    linkFile: GameDesignerPeer,
    link: 'mailto:jobs@peer.inc',
  },
]

export const hrAndFinancesJobs = []

export const dataJobs = []

export const marketingJobs = []

export const engineeringJobs = []

export const otherJobs = [
  {
    id: 201,
    title: 'Senior Technical Artist',
    location: 'Remote',
    time: 'Full Time',
    linkFile: SeniorTechnicalArtistPipelineAndToolsDevelop,
    link: 'mailto:jobs@peer.inc',
  },
]

export const allJobs = [
  ...engineeringJobs,
  ...productAndDesign,
  ...hrAndFinancesJobs,
  ...dataJobs,
  ...marketingJobs,
  ...otherJobs,
]

// export const allJobs = [
//   {
//     id: 1,
//     title: 'Software Engineer',
//     location: 'Remote',
//     time: 'Full Time',
//     linkFile: SoftwareEngineer,
//     link: 'mailto:jobs@peer.inc',
//   },
//   {
//     id: 2,
//     title: 'Python Developer',
//     location: 'Remote',
//     time: 'Full Time',
//     linkFile: PythonDeveloper,
//     link: 'mailto:jobs@peer.inc',
//   },
//   {
//     id: 3,
//     title: 'Product Designer',
//     location: 'Remote',
//     time: 'Full Time',
//     linkFile: ProductDesigner,
//     link: 'mailto:jobs@peer.inc',
//   },
//   {
//     id: 4,
//     title: 'Head of Design',
//     location: 'Remote',
//     time: 'Full Time',
//     linkFile: HeadOfDesign,
//     link: 'mailto:jobs@peer.inc',
//   },
//   {
//     id: 5,
//     title: 'Head of HR',
//     location: 'Remote',
//     time: 'Full Time',
//     linkFile: HeadOfHR,
//     link: 'mailto:jobs@peer.inc',
//   },
//   {
//     id: 6,
//     title: 'Data',
//     location: 'Remote',
//     time: 'Full Time',
//     linkFile: Data,
//     link: 'mailto:jobs@peer.inc',
//   },
//   {
//     id: 7,
//     title: 'Head of Marketing',
//     location: 'Remote',
//     time: 'Full Time',
//     linkFile: HeadOfMarketing,
//     link: 'mailto:jobs@peer.inc',
//   },
//   {
//     id: 8,
//     title: 'Office Manager',
//     location: 'Office',
//     time: 'Part Time',
//     linkFile: OfficeManager,
//     link: 'mailto:jobs@peer.inc',
//   },
// ]

// export const productAndDesign = [
//   {
//     id: 1,
//     title: 'Product Designer',
//     location: 'Remote',
//     time: 'Full Time',
//     linkFile: ProductDesigner,
//     link: 'mailto:jobs@peer.inc',
//   },
//   {
//     id: 2,
//     title: 'Head of Design',
//     location: 'Remote',
//     time: 'Full Time',
//     linkFile: HeadOfDesign,
//     link: 'mailto:jobs@peer.inc',
//   },
// ]

// export const hrAndFinancesJobs = [
//   {
//     id: 1,
//     title: 'Head of HR',
//     location: 'Remote',
//     time: 'Full Time',
//     linkFile: HeadOfHR,
//     link: 'mailto:jobs@peer.inc',
//   },
// ]

// export const dataJobs = [
//   {
//     id: 1,
//     title: 'Data',
//     location: 'Remote',
//     time: 'Full Time',
//     linkFile: Data,
//     link: 'mailto:jobs@peer.inc',
//   },
// ]

// export const marketingJobs = [
//   {
//     id: 1,
//     title: 'Head of Marketing',
//     location: 'Remote',
//     time: 'Full Time',
//     linkFile: HeadOfMarketing,
//     link: 'mailto:jobs@peer.inc',
//   },
// ]

// export const engineeringJobs = [
//   {
//     id: 1,
//     title: 'Software Engineer',
//     location: 'Remote',
//     time: 'Full Time',
//     linkFile: SoftwareEngineer,
//     link: 'mailto:jobs@peer.inc',
//   },
//   {
//     id: 2,
//     title: 'Python Developer',
//     location: 'Remote',
//     time: 'Full Time',
//     linkFile: PythonDeveloper,
//     link: 'mailto:jobs@peer.inc',
//   },
// ]

// export const otherJobs = [
//   {
//     id: 1,
//     title: 'Office Manager',
//     location: 'Office',
//     time: 'Part Time',
//     linkFile: OfficeManager,
//     link: 'mailto:jobs@peer.inc',
//   },
// ]
